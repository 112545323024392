import { useState } from "react";
import Spinner from "../../../ui/Spinner/Spinner";
import { useForm } from "react-hook-form";
import axios from "axios";
import toastMessage from "../../../../utils/toast";
import { parseError } from "../../../../helpers";

const FooterInput = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { register, handleSubmit } = useForm();

  const subscribeUser = async (data) => {
    const url = "https://generatead-backend.vercel.app/api/v1/subscribe/join";
    // const url = "http://localhost:8000/api/v1/subscribe/join";
    setLoading(true);

    try {
      setErrorMessage("");

      const response = await axios({
        method: "POST",
        url,
        data,
        timeout: 120000,
      });

      setErrorMessage("");
      toastMessage("Thank you for subscribing.");
      setLoading(false);
    } catch (error) {
      const { errorMessage: errorMssg } = parseError(error);
      setErrorMessage(errorMssg);
      setLoading(false);
      toastMessage(errorMssg, true);
    }
  };

  const onSubmit = (data) => {
    subscribeUser(data);
  };

  return (
    <div>
      <label for="default-input">Subscribe to our Newsletter</label>
      <form onSubmit={handleSubmit(onSubmit)} className="footer__input">
        <div class="mb-0">
          <input
            type="email"
            placeholder="@ Email Address"
            {...register?.("email", { required: true })}
            name="email"
            class="bg-gray-800 border border-gray-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5  "
            required
          />
        </div>

        <button
          type="submit"
          disabled={loading}
          class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm "
        >
          {loading ? <Spinner /> : "Subscribe"}
        </button>
      </form>
      <p style={{ fontSize: "12px", color: "red", opacity: 0.8 }}>
        {errorMessage}
      </p>
    </div>
  );
};

export default FooterInput;
