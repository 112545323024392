import { useEffect, useState } from "react";
import "./Lists.scss";
import axios from "axios";
import toastMessage from "../../utils/toast";
import { parseError } from "../../helpers";

const Lists = () => {
  const [type, setType] = useState("demos");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const getUrl = () => {
    switch (type) {
      case "demos":
        return "demo/list";
      case "subscribers":
        return "subscribe/list";
      case "waitlist":
        return "waitlist/list";
      default:
        return "";
    }
  };

  const getData = async () => {
    try {
      setList([]);
      const url = `https://generatead-backend.vercel.app/api/v1/${getUrl()}`;
      const response = await axios({
        method: "GET",
        url,
        timeout: 120000,
      });

      setLoading(false);
      setList(response.data.data);
      console.log("reso", response.data.data);
    } catch (error) {
      const { errorMessage: errorMssg } = parseError(error);
      setLoading(false);
      toastMessage(errorMssg, true);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [type]);

  return (
    <div className="lists">
      <h1>
        {loading ? "Fetching New List" : "Fetched List"} ({list?.length})
      </h1>
      <div className="lists__choose">
        <input
          type="radio"
          name="type"
          checked={type === "demos"}
          onChange={() => setType("demos")}
        />
        <label>Demo List</label>
        <input
          type="radio"
          name="type"
          checked={type === "subscribers"}
          onChange={() => setType("subscribers")}
        />
        <label>Subscribers List</label>
        <input
          type="radio"
          name="type"
          checked={type === "waitlist"}
          onChange={() => setType("waitlist")}
        />
        <label>Waitlist List</label>
      </div>

      <div>
        <section className="flex gap-10">
          <span>Email</span>
          <span>First Name</span>
          <span>Last Name</span>
        </section>
        <div>
          {list?.map(({ email, firstName, lastName }, key) => (
            <section key={key} className="flex gap-4">
              <span>{email}</span>
              <span>
                {"| "}
                {firstName || "-"}
              </span>
              <span>
                {"| "}
                {lastName || "-"}
              </span>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Lists;
