import { useState } from "react";
import { parseError } from "../../../helpers";
import { useForm } from "react-hook-form";
import toastMessage from "../../../utils/toast";
import AuthError from "../../../pages/Auth/common/AuthError/AuthError";
// import image from "../../../assets/auth/img_4-removebg-preview.png";
import Spinner from "../../ui/Spinner/Spinner";
// import { Link } from "react-router-dom";
import axios from "axios";

const DemoForm = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [succMessage, setSuccMessage] = useState("");

  const { register, handleSubmit } = useForm();

  const [success, setSuccess] = useState(false);

  const signUpUser = async (data) => {
    // const url = "https://generatead-backend.vercel.app/api/v1/register";
    const url = "https://generatead-backend.vercel.app/api/v1/demo/join";

    try {
      setLoading(true);
      setErrorMessage("");
      setSuccess("");

      const response = await axios({
        method: "POST",
        url,
        data,
        timeout: 120000,
      });

      setSuccess(true);
      setErrorMessage("");
      setSuccMessage(response.data.message);
    } catch (error) {
      const { errorMessage: errorMssg } = parseError(error);
      setErrorMessage(errorMssg);
      setLoading(false);
      setSuccess("");
      toastMessage(errorMssg, true);
    }
  };

  const onSubmit = (data) => {
    const { password, cpassword } = data;
    if (password === cpassword) {
      signUpUser(data);
    } else {
      setErrorMessage("Passwords do not match");
      toastMessage("Passwords do not match", true);
    }
  };

  return (
    <div className="text-white auth">
      <form onSubmit={handleSubmit(onSubmit)} className="auth__form">
        <div className="flex flex-col">
          <div className="py-4">
            <h3>Take the next step!</h3>
            <p style={{ textAlign: "left" }}>
              Kindly enter your email address to request a demo and we will be
              in touch.
            </p>
          </div>

          {Boolean(errorMessage) && <AuthError errorMessage={errorMessage} />}

          {success ? (
            <div
              class="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              {succMessage}
              <br />
              {/* or join our thriving online community on Discord. */}
            </div>
          ) : (
            <div>
              <div class="mb-6">
                <label for="default-input">Email Address</label>
                <input
                  type="email"
                  placeholder="@ Email Address"
                  {...register?.("email", { required: true })}
                  name="email"
                  class="bg-gray-800 border border-gray-900  text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3.5  "
                  required
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                class="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-24 py-2.5 text-center mr-2 mb-2"
              >
                {loading ? <Spinner /> : "Submit"}
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default DemoForm;
